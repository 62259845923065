import {
  type FC,
  type ReactElement,
  memo,
  cloneElement,
  Children,
  useMemo,
} from 'react';
import { type LinkProps } from 'next/link';
import { cn } from '@/lib/classNames';
import i18Next, { Link } from '@/middleware/i18n';
import {
  removeQueryParams,
} from '@/controllers/analytics/analytics.utils/removeQueryParams';
import { Selectors } from '@/lib/selectors';

interface Props extends LinkProps {
  exact?: boolean;
}

export const ActiveLink: FC<Props> = memo<Props>(({
  children, href, exact, ...rest
}) => {
  const router = i18Next.useRouter();
  const asPath = useMemo(() => (
    removeQueryParams(router.asPath)
  ), [router.asPath]);

  const normalizedHref = useMemo(() => {
    if (typeof href === 'string') {
      return removeQueryParams(href);
    }

    return href?.pathname || '';
  }, [href]);

  const active = exact
    ? asPath === normalizedHref
    : asPath?.startsWith(normalizedHref) ?? false;
  const child = Children.only(children) as ReactElement;

  return (
    <Link {...rest} href={href}>
      {cloneElement(child, {
        className: cn(child.props.className, {
          [Selectors.Active]: active,
        }),
      })}
    </Link>
  );
});
