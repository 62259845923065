import { useApolloClient } from '@apollo/client';
import {
  useLogOutMutation,
} from '@/controllers/user/graphql/generated/LogOut.mutation.generated';
import { GoogleAnalyticsClient } from '@/controllers/analytics/analytics.client/GoogleAnalytics.client';
import { MESSAGE_TYPES } from '@/lib/constants/messages';
import { useFlashMessage } from '@/hooks/useFlashMessage';
import { useClearLogOutStorageKeys } from '@/controllers/consultation/useClearLogOutStorageKeys.hook';
import { EMPTY_OBJECT } from '@/constants';

interface Options {
  impossibleToLogoutMessage?: string;
  onCompleted?: () => void;
  onError?: () => void;
}

interface Result {
  logOut: () => Promise<void>;
  called: boolean;
}

export const useLogOut = (options?: Options): Result => {
  const {
    onCompleted,
    onError,
    impossibleToLogoutMessage,
  } = options || EMPTY_OBJECT;

  const client = useApolloClient();
  const clearLogOutStorageKeys = useClearLogOutStorageKeys();
  const {
    showMessage,
  } = useFlashMessage();

  const [logOutMutation, { called }] = useLogOutMutation({
    update: async (_, { data }) => {
      if (!data?.logOut) {
        if (impossibleToLogoutMessage) {
          showMessage({
            type: MESSAGE_TYPES.error,
            text: impossibleToLogoutMessage,
          });
        }

        return;
      }

      await client.clearStore();
      GoogleAnalyticsClient.getInstance().setUser(null);
      clearLogOutStorageKeys();
      // Router.reload();
    },
    onCompleted,
    onError,
  });

  const logOut: () => Promise<void> = async () => {
    await logOutMutation();
  };

  return {
    logOut,
    called,
  };
};
